const styles: { [key: string]: { [key: string]: string | number } } = {
  selectedHeader: {
    color: '#101010',
    fontWeight: '600',
    cursor: 'pointer'
  },
  notSelectedHeader: {
    color: '#9ba1ae',
    fontWeight: '400',
    cursor: 'pointer'
  },
  paper: { width: 600, height: '30vh', borderRadius: 5, p: 3 },
  heading: { color: '#101010' },
  copyBtn: {
    pl: 1,
    pr: 1,
    cursor: 'pointer',
    borderRadius: 3,
    bgcolor: 'primary.backgroundDark',
    color: 'primary.main',
    textTransform: 'capitalize',
    // @ts-ignore
    '&:hover': {
      bgcolor: 'white.main',
      border: '1px solid #DCEDE7'
    }
  },
  copyText: {
    width: '85%',
    color: 'black.contrastText',
    bgcolor: '#f6f6f6',
    p: 1,
    borderRadius: 3
  },
  sendBtn: {
    bgcolor: '#201c26 ',
    border: '1px solid #201c26',
    color: 'white.main',
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    // @ts-ignore
    '&:disabled': {
      border: 0
    },
    // @ts-ignore
    '&:hover': {
      bgcolor: 'white.main',
      border: '1px solid #201c26',
      color: '#201c26'
    }
  }
};

export default styles;

export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name: string, avatarSize = 32) => {
  let initials = '';
  if (name) {
    const splitName = name?.split(' ');
    if (splitName.length > 1) {
      initials = `${splitName[0][0]}${splitName[1][0]}`;
    } else {
      initials = `${splitName[0][0]}`;
    }
  }
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: avatarSize,
      height: avatarSize,
      fontSize: 8
    },
    children: initials
  };
};

import { useContext, useEffect, useState, useRef, memo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// MUI Components
import {
  Chip,
  ListItem,
  Select,
  MenuItem,
  Popover,
  Button,
  TextField,
  Container,
  Stack,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IosShareIcon from '@mui/icons-material/IosShare';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

// utils
import { toNumber } from 'lodash';
import { WHATS_NEW_CONTENT, WHATS_NEW_TABS } from '../utils/constants';
import WhatsNewTab from '../../../components/WhatsNew/WhatsNewTab';

// context
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';

// components
import WhatsNewItem from './WhatsNewItem';
import ShareLinks from '../../../components/Dialogs/ShareLinks';

// styles
import styles from '../styles/Content.styles';
import useQuery from '../../../helpers/customHooks/queryParameter';

const WhatsNew = () => {
  const searchParams = useQuery();
  const location = useLocation();
  const [expanded, setExpandedState] = useState(-1);
  const { state, dispatch } = useContext(GlobalStore);
  const history = useHistory();

  const [currentTab, setCurrentTab] = useState(WHATS_NEW_TABS.all);
  const selectRef = useRef(null);
  const [selectedMonthYear, setSelectedMonthYear] = useState(null);
  const [selectOption, setSelectOption] = useState('all');
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateFilter, setDateFilter] = useState('all');

  const formatDate = date => {
    const d = date.toDateString().slice(4);
    return `${d.slice(0, 6)},${d.slice(6)}`;
  };
  const handleOnclick = id => {
    setExpandedState(prev => (prev === id ? -1 : id));
    const updatedSearchParams = new URLSearchParams(location.search);
    updatedSearchParams.set('feature', id.toString());
    window.history.replaceState(null, '', `${location.pathname}?${updatedSearchParams.toString()}`);
  };

  const handleShareClick = async feature => {
    const textPrefix = `What's New: ${feature.header}`;
    const link = `${window.location.href.split('help')[0]}help?content=whatsnew&feature=${
      feature.id
    }`;
    await dispatch({
      type: GlobalActions.SET_SHARE_LINK,
      value: {
        entityType: 'featureLink',
        entityDetails: { link },
        open: true,
        textPrefix
      }
    });
    history.push(`/help?content=whatsnew&feature=${feature.id}`);
  };

  const handleTabChange = tab => {
    setCurrentTab(tab);
  };

  const handleDoneAfterSelectingMonthYear = () => {
    if (selectedMonthYear) {
      setDateFilter(selectedMonthYear);
    }
    setAnchorEl(null);
  };

  const handleSelectChange = event => {
    const { value } = event.target;
    if (value === 'monthYear') {
      setAnchorEl(selectRef.current);
    } else {
      setDateFilter('all');
      setSelectedMonthYear(null);
    }
    setSelectOption(value);
  };

  const handleSelectMonthYear = () => {
    setAnchorEl(selectRef.current);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setDateFilter('all');
  };

  const handleDateChange = newValue => {
    setSelectedMonthYear(newValue);
  };

  useEffect(() => {
    if (searchParams.get('feature')) {
      setCurrentTab(WHATS_NEW_TABS.oldUpdates);
      const element = document.getElementById(`whats-new-${searchParams.get('feature')}`);
      if (element) {
        setExpandedState(toNumber(searchParams.get('feature')));
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 1000);
      }
    }
  }, [searchParams]);

  return (
    <Container sx={{ width: 926 }}>
      <Box display='flex' justifyContent='space-between' alignItems='baseline'>
        <Box display='flex' justifyContent='center' gap={1.5}>
          <ListItem sx={styles.chipsListItem}>
            <Chip
              sx={{
                ...styles.chip,
                cursor: currentTab === WHATS_NEW_TABS.all ? 'default' : 'pointer'
              }}
              variant={currentTab === WHATS_NEW_TABS.all ? 'filled' : 'outlined'}
              label={WHATS_NEW_TABS.all}
              onClick={() => handleTabChange(WHATS_NEW_TABS.all)}
            />
          </ListItem>
          <ListItem sx={styles.chipsListItem}>
            <Chip
              sx={{
                ...styles.chip,
                cursor: currentTab === WHATS_NEW_TABS.majorUpdates ? 'default' : 'pointer'
              }}
              variant={currentTab === WHATS_NEW_TABS.majorUpdates ? 'filled' : 'outlined'}
              label={WHATS_NEW_TABS.majorUpdates}
              onClick={() => handleTabChange(WHATS_NEW_TABS.majorUpdates)}
            />
          </ListItem>
          <ListItem sx={styles.chipsListItem}>
            <Chip
              sx={{
                ...styles.chip,
                cursor: currentTab === WHATS_NEW_TABS.enhancementUpdates ? 'default' : 'pointer'
              }}
              variant={currentTab === WHATS_NEW_TABS.enhancementUpdates ? 'filled' : 'outlined'}
              label={WHATS_NEW_TABS.enhancementUpdates}
              onClick={() => handleTabChange(WHATS_NEW_TABS.enhancementUpdates)}
            />
          </ListItem>
        </Box>

        {[
          WHATS_NEW_TABS.all,
          WHATS_NEW_TABS.majorUpdates,
          WHATS_NEW_TABS.enhancementUpdates
        ].includes(currentTab) ? (
          <Box display='flex' alignItems='center' gap={0.5} width={210} justifyContent='end'>
            <Typography>Select month/year: </Typography>
            <Select
              value={selectOption}
              onChange={handleSelectChange}
              displayEmpty
              size='small'
              ref={selectRef}
              renderValue={selected => {
                if (selected === 'all') return 'All';
                if (selected === 'monthYear') {
                  if (dateFilter === 'all' || !(dateFilter instanceof Date)) {
                    return '';
                  }

                  return dateFilter?.toLocaleString('default', {
                    month: 'short',
                    year: 'numeric'
                  });
                }

                return selected;
              }}>
              <MenuItem value='all'>All</MenuItem>
              <MenuItem value='monthYear' onClick={handleSelectMonthYear}>
                Select Month/Year
              </MenuItem>
            </Select>

            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}>
              <Box p={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <StaticDatePicker
                    displayStaticWrapperAs='desktop'
                    views={['year', 'month']}
                    openTo='year'
                    value={selectedMonthYear}
                    onChange={handleDateChange}
                    minDate={new Date('2022-03-02')}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <Button onClick={handleDoneAfterSelectingMonthYear} color='primary'>
                  Done
                </Button>
              </Box>
            </Popover>
          </Box>
        ) : (
          <Box width={204} />
        )}
      </Box>

      {[
        WHATS_NEW_TABS.all,
        WHATS_NEW_TABS.majorUpdates,
        WHATS_NEW_TABS.enhancementUpdates
      ].includes(currentTab) && <WhatsNewTab updateType={currentTab} dateFilter={dateFilter} />}

      {currentTab === WHATS_NEW_TABS.oldUpdates && (
        <>
          <Stack spacing={2} mb={5} mt={1.75}>
            {WHATS_NEW_CONTENT?.map(feature => (
              <Accordion
                key={feature.id}
                id={`whats-new-${feature.id}`}
                disableGutters={false}
                elavation={0}
                square
                expanded={expanded === feature.id}
                sx={styles.accordian}>
                <AccordionSummary
                  onClick={() => handleOnclick(feature.id)}
                  expandIcon={<ExpandMoreIcon fontSize='medium' sx={styles.expandIcon} />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'>
                  <Box flexDirection='row' display='flex' alignItems='center' width='100%'>
                    <Typography sx={styles.titleDate}>{formatDate(feature.date)}</Typography>
                    <Typography sx={styles.title}>{feature.header}</Typography>
                  </Box>
                  <IconButton onClick={() => handleShareClick(feature)} size='small'>
                    <IosShareIcon fontSize='small' sx={styles.closeBtn} />
                  </IconButton>
                </AccordionSummary>
                <AccordionDetails>
                  <WhatsNewItem feature={feature}> </WhatsNewItem>
                </AccordionDetails>
              </Accordion>
            ))}
          </Stack>
          {state.shareLink?.open && <ShareLinks />}
        </>
      )}
    </Container>
  );
};

export default memo(WhatsNew);

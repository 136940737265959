/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";

// muiv5
import Avatar from "@mui/material/Avatar";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import AvatarGroup from "@mui/material/AvatarGroup";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";

// icons
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

// utils
import { stringAvatar } from "../../helpers/utils";

const BinderList = ({
  binder,
  handleSelectBinder,
  selectedBinders
}: {
  binder: any;
  handleSelectBinder: any;
  selectedBinders: any;
}) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const leaveTimer = React.useRef<any>();
  const leaveDelay = 100;
  const handleLeave = () => {
    clearTimeout(leaveTimer.current);
    leaveTimer.current = setTimeout(() => {
      setAnchorEl(null);
    }, leaveDelay);
  };

  return (
    <>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement='bottom-start'
        onMouseEnter={() => {
          clearTimeout(leaveTimer.current);
        }}
        sx={{ zIndex: 9999 }}
        onMouseLeave={handleLeave}
      >
        <Paper
          elevation={3}
          sx={{
            maxHeight: 300,
            minWidth: 250,
            overflow: 'auto',
            borderRadius: 2,
            '&::-webkit-scrollbar': {
              width: '0.4em'
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              marginY: '5px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,0.26)',
              marginY: '5px'
            }
          }}
        >
          <List component='div' disablePadding>
            {binder.users?.map((userDetails: any) => (
              <ListItem key={userDetails.user.id}>
                <Avatar
                  key={`${userDetails.user.id}-avatar`}
                  {...stringAvatar(userDetails.user.name, 24)}
                  alt='group'
                  variant='circular'
                />
                <ListItemText primary={userDetails.user.name} sx={{ ml: 1 }} />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Popper>
      <ListItem key={binder.id} disablePadding>
        <ListItemButton
          role={undefined}
          onClick={() => handleSelectBinder(binder)}
          disableRipple
          disableGutters
          sx={{ paddingX: 1 }}
        >
          <AvatarGroup
            max={3}
            spacing='medium'
            componentsProps={{
              additionalAvatar: {
                onMouseEnter: event => {
                  clearTimeout(leaveTimer.current);
                  setAnchorEl(event.currentTarget);
                },
                onMouseLeave: handleLeave,
                sx: {
                  width: 32,
                  height: 32,
                  fontSize: 12,
                  transition: 'transform .2s',
                  '&:hover': {
                    transform: 'scale(1.15)'
                  }
                }
              }
            }}
            sx={{ cursor: 'pointer', mr: 1 }}
          >
            {binder.users?.map((userDetails: any) => {
              return (
                <Tooltip key={userDetails.user.id} title={userDetails.user.name}>
                  <Avatar
                    key={userDetails.user.id}
                    {...stringAvatar(userDetails.user.name)}
                    alt='group'
                    variant='circular'
                  />
                </Tooltip>
              );
            })}
          </AvatarGroup>
          <ListItemText primary={binder.name} />
          <ListItemIcon sx={{ minWidth: 0 }}>
            <Checkbox
              edge={false}
              checked={selectedBinders.some((selectedItem: any) => selectedItem.id === binder.id)}
              tabIndex={-1}
              icon={<RadioButtonUncheckedIcon fontSize='small' />}
              checkedIcon={<TaskAltIcon fontSize='small' sx={{ color: '#201c26' }} />}
            />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
    </>
  );
};

export default React.memo(BinderList);

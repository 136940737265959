import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  Popover,
  ButtonGroup,
  Button,
  IconButton,
  Tooltip
} from '@mui/material';
import uniqueId from 'lodash/uniqueId';

import SyncIcon from '@mui/icons-material/Sync';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { debounce } from 'lodash';
import Spacer from '../../../assets/svgs/Report/SpacerIcon';

import Store from '../../../store';
import Actions from '../../../store/actions';
import BorderedButton from './BorderedButton';
import { saveReport, updateReport } from '../../../api/pages/UserProfile';
import { getReportPDF } from '../../../api/modules/Reports';

import NameConfirmationDialog from './NameConfirmationDialog';
import { REPORT_DOWNLOAD_FORMAT } from '../constants';
import ShareLinks from '../../Dialogs/ShareLinks';
import { ShareIcon } from '../../../assets/svgs/Icons';

import styles from './Tools.styles';

type REPORT_DOWNLOAD_FORMAT_OPTIONS =
  | typeof REPORT_DOWNLOAD_FORMAT.PDF
  | typeof REPORT_DOWNLOAD_FORMAT.DOCX;

const Tools = ({
  layout,
  reportId,
  onCoverPageSelectionClick,
  onTextWidgetAdded,
  onSpacerAdded,
  defaultFileName,
  defaultFileDescription
}: any) => {
  const { state, dispatch } = useContext<any>(Store);
  const [name, setName] = useState<string>('Untitled');
  const [description, setDescription] = useState<string>('');
  const [id, setReportId] = useState<any>(reportId);
  const [isDownloading, setDownloading] = useState<boolean>(false);

  const nameRef = useRef<any>();
  const descRef = useRef<any>();

  const savingRef = useRef<boolean>(false);
  const saveConfirmedWithUntitled = useRef<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [showSaveConfirmation, setSaveConfirmation] = useState<boolean>(false);
  const [showDownloadConfirmation, setDownloadConfirmation] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [downloadFormat, setDownloadFormat] = useState<REPORT_DOWNLOAD_FORMAT_OPTIONS>(
    REPORT_DOWNLOAD_FORMAT.PDF
  );

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    defaultFileName && setName(defaultFileName || 'Untitled');
    // eslint-disable-next-line no-unused-expressions
    defaultFileDescription && setDescription(defaultFileDescription);
  }, [defaultFileName, defaultFileDescription]);

  const scrollToElement = useCallback((elementId: string) => {
    setTimeout(() => {
      document.getElementById(elementId)?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    }, 500);
  }, []);

  const handleTextWidgetAdd = useCallback(() => {
    const random = Math.floor(Math.random() * 1000);
    const sectionId = uniqueId(`report_preview_${random}_`);
    dispatch({
      type: Actions.ADD_TEMPLATE_TO_REPORT,
      value: {
        dataSource: 'custom',
        sectionType: 'TEXT',
        textType: 'HTML_TEXT',
        id: sectionId,
        style: {
          placement: {
            h: 10,
            w: 12
          }
        },
        content: '<h3>Enter your text here...</h3>'
      }
    });
    scrollToElement(`${sectionId}`);
    onTextWidgetAdded(sectionId);
  }, []);

  const handleSpacerWidgetAdd = useCallback(() => {
    const random = Math.floor(Math.random() * 1000);
    const sectionId = uniqueId(`report_preview_${random}_`);
    dispatch({
      type: Actions.ADD_TEMPLATE_TO_REPORT,
      value: {
        sectionType: 'SPACER',
        id: sectionId,
        style: {
          placement: {
            h: 10,
            w: 12
          }
        }
      }
    });
    scrollToElement(`${sectionId}`);
    onSpacerAdded(sectionId);
  }, []);

  const debounceSaveReport = useCallback(
    debounce(async (_, force = false) => {
      if (!saveConfirmedWithUntitled.current && !force && nameRef?.current?.value === 'Untitled') {
        setSaveConfirmation(true);
        saveConfirmedWithUntitled.current = true;
        return;
      }
      setSaveConfirmation(false);
      setDownloadConfirmation(false);
      if (savingRef.current || !nameRef?.current || !descRef?.current) {
        return;
      }
      const payload = {
        report_title: nameRef.current.value,
        report_description: descRef.current.value,
        layout
      };
      setIsSaving(true);
      savingRef.current = true;
      dispatch({
        type: Actions.REPORT_SAVING,
        value: true
      });
      try {
        const { data: response } = id ? await updateReport(id, payload) : await saveReport(payload);
        if (response) {
          if (response.Success && !response.error && response.Success.id) {
            setReportId(response.Success.id);
            dispatch({
              type: Actions.SET_REPORT_ID,
              value: {
                id: response.Success.id,
                reportFileName: response.Success.name,
                reportFileDescription: descRef.current.value
              }
            });
          }

          dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: 'Successfully saved your report.', color: 'success' }
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        dispatch({
          type: Actions.SET_ALERT,
          value: {
            status: true,
            message: 'Something went wrong. Could not download your report.',
            color: 'error'
          }
        });
      } finally {
        setIsSaving(false);
        dispatch({
          type: Actions.REPORT_SAVING,
          value: false
        });
        savingRef.current = false;
      }
    }, 1000),
    [layout, id]
  );

  const download = useCallback((blob: Blob, fileType: REPORT_DOWNLOAD_FORMAT_OPTIONS) => {
    // Create a URL for the Blob
    const fileUrl = URL.createObjectURL(blob);

    // Create a download link
    const downloadLink = document.createElement('a');
    downloadLink.href = fileUrl;
    downloadLink.download = `${nameRef.current.value}.${
      fileType === REPORT_DOWNLOAD_FORMAT.PDF
        ? REPORT_DOWNLOAD_FORMAT.PDF
        : REPORT_DOWNLOAD_FORMAT.DOCX
    }`; // Set the desired filename for the downloaded PDF
    downloadLink.textContent =
      fileType === REPORT_DOWNLOAD_FORMAT.PDF ? 'Download PDF' : 'Download DOC';

    // Append the download link to the DOM
    document.body.appendChild(downloadLink);

    // Optionally, simulate a click on the link to trigger the download
    downloadLink.click();

    setTimeout(() => {
      document.body.removeChild(downloadLink);
    }, 1000);

    dispatch({
      type: Actions.SET_ALERT,
      value: { status: true, message: 'Successfully downloaded your report.', color: 'success' }
    });
  }, []);

  const handleDownload = useCallback(
    async (
      _: any,
      force: boolean = false,
      fileType: REPORT_DOWNLOAD_FORMAT_OPTIONS = REPORT_DOWNLOAD_FORMAT.PDF
    ) => {
      // If user has not updated the name of the report, ask them to add the report.
      // if they agrred to keep untitled, force would be `true`.
      if (!force && nameRef?.current?.value === 'Untitled') {
        setDownloadFormat(fileType);
        setDownloadConfirmation(true);
        return;
      }

      // Reset the name popup
      setSaveConfirmation(false);
      setDownloadConfirmation(false);

      try {
        setDownloading(true);
        dispatch({
          type: Actions.REPORT_DOWNLOADING,
          value: true
        });
        const response = await getReportPDF({ fileType, layout });
        if (response) {
          // setGraphsData(response.data.Success);
          // (window as any).reportLoaded = true;
          download(response.data, fileType);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        setDownloading(false);
        dispatch({
          type: Actions.REPORT_DOWNLOADING,
          value: false
        });
      }
    },
    [layout, isDownloading]
  );

  const handleFileNameChange = (event: any) => {
    const inputValue = event.target.value;
    if (inputValue.length <= 20) {
      setName(inputValue);
    }
  };
  const handleDescriptionChange = (event: any) => {
    const inputValue = event.target.value;
    if (inputValue.length <= 60) {
      setDescription(inputValue);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShare = async () => {
    try {
      dispatch({
        type: Actions.REPORT_DOWNLOADING_FOR_SHARING,
        value: true
      });
      const response = await getReportPDF({ fileType: REPORT_DOWNLOAD_FORMAT.PDF, layout });
      if (response?.data) {
        const { data } = response;
        const fileUrl = URL.createObjectURL(data);
        const shareObject = {
          entityType: 'file',
          open: true,
          entityDetails: {
            link: fileUrl,
            title: name
          }
        };
        await dispatch({
          type: Actions.SET_SHARE_LINK,
          value: shareObject
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({
        type: Actions.REPORT_DOWNLOADING_FOR_SHARING,
        value: false
      });
    }
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  return (
    <>
      <Box className='no-print' height='100%' bgcolor='white.main' position='relative'>
        <Box sx={styles.toolsHeader}>
          <Box>
            <Typography variant='h4' fontWeight='bold'>
              Format Report
            </Typography>
          </Box>
          <Box display='flex' alignItems='center' ml='auto'>
            {isSaving && (
              <Box display='flex' alignItems='center'>
                <SyncIcon sx={styles.syncIcon} />
                <Typography fontSize={14} pl={0.5}>
                  Saving...
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          <Box p={4}>
            <Typography fontSize={16} fontWeight='bold'>
              File Details:
            </Typography>
            <Box display='flex' alignItems='start' flexDirection='column' pt={2}>
              <Typography pr={2} color='gray.sonicSilver' pb={1}>
                Name:
              </Typography>
              <TextField
                id='filename_input'
                variant='standard'
                value={name}
                inputRef={nameRef}
                sx={{ width: '100%' }}
                onChange={handleFileNameChange}
                helperText={`${name.length}/20 characters`}
              />
            </Box>
            <Box display='flex' alignItems='start' pt={4} flexDirection='column'>
              <Typography pr={2} color='gray.sonicSilver' pb={1}>
                Description:
              </Typography>
              <TextField
                id='filename_description'
                variant='standard'
                multiline
                value={description}
                inputRef={descRef}
                sx={{ width: '100%' }}
                onChange={handleDescriptionChange}
                helperText={`${description.length}/60 characters`}
              />
            </Box>
          </Box>
          <Box p={4} pt={5}>
            <Typography fontSize={16} fontWeight='bold'>
              Add module:
            </Typography>
            <Box display='flex' alignItems='center' mt={2}>
              <BorderedButton
                name='Text'
                tooltipText='Add free text'
                iconComponent={
                  <TextSnippetOutlinedIcon sx={{ color: 'gray.sonicSilver', fontSize: 22 }} />
                }
                onClick={handleTextWidgetAdd}
              />
              <BorderedButton
                name='Spacer'
                tooltipText='Add Space'
                iconComponent={<Spacer />}
                onClick={handleSpacerWidgetAdd}
              />
              <BorderedButton
                name='Cover'
                tooltipText='Update Coverpage'
                iconComponent={
                  <TextSnippetOutlinedIcon sx={{ color: 'gray.sonicSilver', fontSize: 22 }} />
                }
                onClick={onCoverPageSelectionClick}
              />
            </Box>
          </Box>
          <Box sx={styles.buttonContainer}>
            <LoadingButton
              variant='outlined'
              onClick={debounceSaveReport}
              loading={isSaving}
              loadingPosition='start'
              startIcon={<SaveIcon />}
              sx={styles.saveButton}>
              Save Report
            </LoadingButton>
            <LoadingButton
              variant='contained'
              aria-describedby={popoverId}
              onClick={handleClick}
              loading={isDownloading}
              loadingPosition='start'
              startIcon={<CloudDownloadIcon />}
              sx={styles.downloadButton}>
              Download
            </LoadingButton>
            <Popover
              id={popoverId}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              sx={{ mt: '-6px', ml: '-1px' }}>
              <ButtonGroup orientation='vertical'>
                <Button
                  sx={styles.downloadOption}
                  onClick={ev => {
                    handleClose();
                    handleDownload(ev, false, REPORT_DOWNLOAD_FORMAT.DOCX);
                  }}>
                  Microsoft Word (.docx)
                </Button>
                <Button
                  sx={styles.downloadOption}
                  onClick={ev => {
                    handleClose();
                    handleDownload(ev, false, REPORT_DOWNLOAD_FORMAT.PDF);
                  }}>
                  PDF Document (.pdf)
                </Button>
              </ButtonGroup>
            </Popover>
          </Box>
          {(showDownloadConfirmation || showSaveConfirmation) && (
            <NameConfirmationDialog
              open={showDownloadConfirmation || showSaveConfirmation}
              name={name}
              description={description}
              onClose={() => {
                setDownloadConfirmation(false);
                setSaveConfirmation(false);
              }}
              onSave={
                showDownloadConfirmation
                  ? (e: any) => handleDownload(e, true, downloadFormat)
                  : (e: any) => debounceSaveReport(e, true)
              }
              buttonText={showDownloadConfirmation ? 'Download' : 'Save'}
              onDescriptionChange={(e: any) => setDescription(e.target.value)}
              onNameChange={(e: any) => setName(e.target.value)}
            />
          )}
        </Box>
      </Box>
      <Box sx={styles.shareIcon} className='no-print'>
        <Tooltip title='Share through collaborate'>
          <IconButton onClick={handleShare}>
            <ShareIcon sx={{ fontSize: 18, color: 'black.main' }} />
          </IconButton>
        </Tooltip>
      </Box>
      {state.shareLink?.open && <ShareLinks />}
    </>
  );
};

export default React.memo(Tools);

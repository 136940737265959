/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import InputAdornment from '@mui/material/InputAdornment';

// icons
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SearchIcon from '@mui/icons-material/Search';

import { Container, Tooltip } from '@mui/material';
import { Add, KeyboardBackspace } from '@mui/icons-material';
import themev5 from '../../themev5';

import styles from './styles/ShareLinks.styles';

// context
import Store from '../../store';
import Actions from '../../store/actions';

// hooks
import useShareLinks from '../../hooks/useShareLinks';

// components
import BinderList from './BinderList';

// constants
import { linksEntity } from '../../hooks/constants';
import CreateNewGroup from './CreateNewGroup';

export const ShareLinks = () => {
  const { state, dispatch } = useContext(Store) as any;
  const [createNewGroup, setCreateNewGroup] = React.useState(false);
  const {
    binders,
    selectedBinders,
    handleSelectBinder,
    removeBinderFromChips,
    handleCopy,
    sendMessageToGroup,
    sendLoader,
    handleAdditionalText,
    additionalMessage,
    setBinderType,
    binderType,
    serviceRequestBinders,
    searchText,
    handleSearchText,
    clearSearchText,
    getBinders
  } = useShareLinks();

  const handleClose = () => {
    dispatch({
      type: Actions.SET_SHARE_LINK,
      value: { link: '', open: false }
    });
  };
  const handleCloseCreateGroup = async () => {
    setCreateNewGroup(false);
    await getBinders();
  };
  return (
    <ThemeProvider theme={themev5}>
      <Dialog
        onClose={handleClose}
        sx={{
          backdropFilter: 'blur(1px)',
          borderRadius: 5,
          '& .MuiDialog-paper': { borderRadius: 5 }
        }}
        aria-labelledby='customized-dialog-title'
        open={state.shareLink?.open}>
        <DialogTitle>
          {/* title name */}
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography variant='subtitle1' fontWeight={600} sx={state.heading}>
              {state.shareLink.entityType === 'file'
                ? 'Share file with others'
                : 'Share link with others'}
            </Typography>
            {/* @ts-ignore */}
            <IconButton size='small' onClick={handleClose}>
              <HighlightOffIcon fontSize='small' sx={{ color: '#101010' }} />
            </IconButton>
          </Stack>
          <Divider sx={{ mt: 1, color: '#9ba1ae' }} />

          {/* copy link button */}
          {linksEntity.includes(state.shareLink?.entityType) && !createNewGroup ? (
            <>
              <Stack mt={2} spacing={1}>
                <Typography variant='subtitle2' sx={{ color: '#9ba1ae', fontWeight: '400' }}>
                  Copy Link
                </Typography>
                <Stack direction='row' justifyContent='space-between'>
                  <Typography noWrap variant='subtitle1' sx={styles.copyText}>
                    {state.shareLink.entityDetails.link}
                  </Typography>
                  <Button
                    variant='contained'
                    size='small'
                    disableElevation
                    endIcon={<ContentCopyIcon fontSize='small' />}
                    onClick={() => handleCopy(state.shareLink.entityDetails.link)}
                    sx={styles.copyBtn}>
                    Copy
                  </Button>
                </Stack>
              </Stack>

              <Divider sx={{ mt: 2 }} />
            </>
          ) : null}
          <Stack direction='row' mt={2} spacing={1} alignItems='center' width='100%'>
            {createNewGroup ? (
              <Typography variant='subtitle2' sx={{ ...styles.selectedHeader, cursor: 'initial' }}>
                New Group
              </Typography>
            ) : (
              <>
                <Typography
                  onClick={() => {
                    clearSearchText();
                    setBinderType('chats');
                  }}
                  variant='subtitle2'
                  sx={binderType === 'chats' ? styles.selectedHeader : styles.notSelectedHeader}>
                  Collaborations
                </Typography>
                <Divider orientation='vertical' sx={{ height: '20px' }} />
                <Typography
                  onClick={() => {
                    clearSearchText();
                    setBinderType('serviceRequests');
                  }}
                  variant='subtitle2'
                  sx={
                    binderType === 'serviceRequests'
                      ? styles.selectedHeader
                      : styles.notSelectedHeader
                  }>
                  Service Requests
                </Typography>
              </>
            )}
            {createNewGroup ? (
              <Button
                variant='contained'
                size='small'
                disableElevation
                startIcon={<KeyboardBackspace />}
                onClick={() => setCreateNewGroup(!createNewGroup)}
                sx={{ ...styles.copyBtn, ml: 'auto !important' }}>
                Go back to sharing
              </Button>
            ) : (
              <Tooltip title='Create new group'>
                <Button
                  variant='contained'
                  size='small'
                  disableElevation
                  endIcon={<Add fontSize='small' />}
                  onClick={() => setCreateNewGroup(!createNewGroup)}
                  sx={{
                    ml: 'auto !important',
                    color: 'white !important',
                    textTransform: 'none'
                  }}>
                  New
                </Button>
              </Tooltip>
            )}
          </Stack>
          {/* search organization */}
          {!createNewGroup && (
            <TextField
              id='search-group'
              placeholder='Search for people/groups'
              variant='outlined'
              fullWidth
              disabled={
                (binderType === 'chats' && binders?.length <= 0) ||
                (binderType === 'serviceRequests' && serviceRequestBinders?.length <= 0)
              }
              value={searchText}
              onChange={handleSearchText}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <SearchIcon />
                  </InputAdornment>
                ),
                sx: { borderRadius: 3 }
              }}
              sx={{ mt: 1 }}
            />
          )}
        </DialogTitle>
        {createNewGroup ? (
          <CreateNewGroup closeCreateGroupDialog={handleCloseCreateGroup} />
        ) : (
          <>
            <DialogContent sx={styles.paper}>
              <Stack sx={{ height: '75%' }} data-dd-privacy='mask'>
                {/* binder list */}
                {binderType === 'chats' && (
                  <List sx={{ width: '100%' }} disablePadding>
                    {binders
                      ?.filter(
                        (binder: any) =>
                          binder?.users?.some((userDetails: any) =>
                            userDetails?.user.name
                              .toLowerCase()
                              .trim()
                              .includes(searchText.toLowerCase().trim())
                          ) ||
                          binder?.name
                            .toLowerCase()
                            .trim()
                            .includes(searchText.toLowerCase().trim())
                      )
                      ?.sort((a: any, b: any) => b.updatedAt - a.updatedAt)
                      ?.map((binder: any) => (
                        <BinderList
                          key={binder.id}
                          binder={binder}
                          handleSelectBinder={handleSelectBinder}
                          selectedBinders={selectedBinders}
                        />
                      ))}
                  </List>
                )}
                {binderType === 'serviceRequests' && (
                  <List sx={{ width: '100%' }} disablePadding>
                    {serviceRequestBinders
                      ?.filter(
                        (binder: any) =>
                          binder?.users?.some((userDetails: any) =>
                            userDetails?.user.name
                              .toLowerCase()
                              .trim()
                              .includes(searchText.toLowerCase().trim())
                          ) ||
                          binder?.name
                            .toLowerCase()
                            .trim()
                            .includes(searchText.toLowerCase().trim())
                      )
                      ?.map((binder: any) => (
                        <BinderList
                          key={binder.id}
                          binder={binder}
                          handleSelectBinder={handleSelectBinder}
                          selectedBinders={selectedBinders}
                        />
                      ))}
                  </List>
                )}
                {binderType === 'chats' && binders?.length === 0 ? (
                  <Typography variant='subtitle2' sx={{ mt: 2 }}>
                    No grouped conversations available
                  </Typography>
                ) : null}
                {binderType === 'serviceRequests' && serviceRequestBinders?.length === 0 ? (
                  <Typography variant='subtitle2' sx={{ mt: 2 }}>
                    No service requests available
                  </Typography>
                ) : null}
              </Stack>
            </DialogContent>
            <DialogActions>
              {/* selected group chips and send button */}
              <Stack
                spacing={1}
                sx={{
                  width: '100%',
                  pl: 2,
                  pr: 2,
                  pb: 4
                }}>
                <Stack
                  direction='row'
                  alignItems='center'
                  p={1}
                  spacing={1}
                  sx={{
                    overflowX: 'auto',
                    borderRadius: 2
                  }}>
                  {/* Blank box to keep the height of the dialog consistent */}
                  {selectedBinders.length === 0 ? (
                    <Container sx={{ height: '24px' }} />
                  ) : (
                    selectedBinders?.map((binder: any) => {
                      return (
                        <Chip
                          key={binder.id}
                          label={binder.name}
                          size='small'
                          sx={{
                            color: '#636e72',
                            bgcolor: '#f6f6f6'
                          }}
                          onDelete={() => removeBinderFromChips(binder.id)}
                        />
                      );
                    })
                  )}
                </Stack>
                <Stack spacing={1} direction='row' justifyContent='space-between'>
                  <TextField
                    id='outlined-basic'
                    fullWidth
                    placeholder='Write message here...'
                    variant='outlined'
                    size='small'
                    disabled={selectedBinders?.length <= 0}
                    value={additionalMessage}
                    onChange={handleAdditionalText}
                  />
                  <LoadingButton
                    onClick={sendMessageToGroup}
                    loading={sendLoader}
                    loadingPosition='end'
                    disabled={selectedBinders?.length <= 0}
                    variant='contained'
                    endIcon={<SendOutlinedIcon fontSize='small' />}
                    size='small'
                    color='primary'
                    sx={styles.sendBtn}>
                    Send
                  </LoadingButton>
                </Stack>
              </Stack>
            </DialogActions>
          </>
        )}
      </Dialog>
    </ThemeProvider>
  );
};

export default ShareLinks;

/* eslint-disable no-underscore-dangle */
import { useContext, useState } from 'react';
import { PropTypes } from 'prop-types';

// material ui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

// icons
import CircleIcon from '@mui/icons-material/Circle';
import { isArray } from 'lodash';
import { CircularProgress } from '@mui/material';
import { IosShare } from '@mui/icons-material';
import Reg360Icon from '../../../assets/svgs/CardsPage/Reg360Icon';

// utils
import {
  ariaCardResultsMapping,
  scoreConfidenceStyles,
  SOURCE_WITH_REG360,
  SOURCE_WITH_SUBTITLE,
  TAG_KEYS,
  UNIQUE_FIELD_MAPPING
} from '../utils/constants';

import { highlightPDF } from '../../../api/pages/Aria';

// styled components
import Tags from '../styledComponents/Tags';

import customTitleCase from '../../../utils/titleCase';
import FloatingActions from './FloatingActions';
import CardActions from '../../CardsPage/components/CardActions';
import getReg360Url from '../utils/getReg360Url';

// styles
import styles from '../styles/AriaResult.styles';

// store
import Store from '../../../store';
import GlobalActions from '../../../store/actions';

// custom hooks
import useQuery from '../../../helpers/customHooks/queryParameter';
import ShareLinks from '../../../components/Dialogs/ShareLinks';

export const getAriaResultTitle = (title, result, type) => {
  if (!title) {
    if (type === 'us-devices') {
      if (isArray(result?.sponsor)) {
        return result?.sponsor.join(' ') || '';
      }
      return result?.sponsor || '';
    }
    return '';
  }
  if (isArray(title)) {
    return title.join(' ') || '';
  }
  return title || '';
};

const AriaResult = ({
  item,
  selected,
  ariaResultFeedback,
  ariaClickAnswerEvent,
  source,
  module,
  selectedDocumentId,
  searchId,
  showFloatingButtons = false,
  suggestedAnswer = false,
  fromUserProfile = false
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [floatingActionsLoading, setFloatingActionsLoading] = useState({
    favorite: false,
    collaborate: false
  });

  const { state, dispatch } = useContext(Store);
  // get the query arguments from url
  const query = useQuery();

  const getAriaResultBoxStyle = () => {
    if (suggestedAnswer) {
      if (selectedDocumentId === item.result_id || isMouseOver) {
        return styles.suggestedResultContainerHover;
      }
      return styles.suggestedResultContainer;
    }
    if (fromUserProfile) {
      if (selectedDocumentId === item.result_id || isMouseOver) {
        return styles.userProfileResultContainerHover;
      }
      return styles.userProfileResultContainer;
    }
    if (selectedDocumentId === item.result_id || isMouseOver) {
      return styles.resultContainerHover;
    }
    return styles.resultContainer;
  };

  const getHighlightedPDFURL = async () => {
    const textToHighlight = item.text.replaceAll('<b>', '').replaceAll('</b>', '');
    const listOfTextToHighlight = item.highlighted_words;
    const payload = {
      s3_link: item.document_url,
      text_to_highlight: listOfTextToHighlight,
      phrase_to_highlight: textToHighlight
    };
    const pageNumber = item.document_url.split('#page=').pop();
    const res = await highlightPDF(payload);

    if (res?.status === 200) {
      const file = new Blob([res.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      return `${fileURL}#page=${pageNumber}`;
    }
    return item.document_url;
  };

  const handleShareClick = async () => {
    setFloatingActionsLoading(prev => {
      return { ...prev, collaborate: true };
    });
    const pdfLink = await getHighlightedPDFURL();
    await dispatch({
      type: GlobalActions.SET_SHARE_LINK,
      value: {
        entityType: 'aria_result_link',
        // eslint-disable-next-line no-underscore-dangle
        entityDetails: {
          link: pdfLink,
          title: item.category || item._category,
          text: item.text,
          s3ResourceLink: item.document_url
        },
        open: true,
        textPrefix: `Aria search: ${query.get('term')}`,
        ariaResultId: item.result_id
      }
    });
    setFloatingActionsLoading(prev => {
      return { ...prev, collaborate: false };
    });
  };

  const handleShowReg360Icon = data => {
    if (data.data_source === 'CDER-EUA') {
      return false;
    }
    if (data['data-source'] === 'HPRA' && data['product-number'][0].startsWith('EU')) {
      return false;
    }
    return SOURCE_WITH_REG360?.includes(source?.toLowerCase());
  };

  const getHPRAProductNumber = productNumber => {
    if (Number.isNaN(Number(productNumber))) {
      return productNumber;
    }
    return `EMEA-H-C-${productNumber}`;
  };

  return (
    <Box
      key={item.result_id}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      sx={getAriaResultBoxStyle()}>
      <Stack>
        {(selectedDocumentId === item.result_id || isMouseOver) && (
          <div>
            <FloatingActions
              id={item.result_id}
              likeFilled={selected.some(
                feedback => feedback.type === 'RELEVANT' && feedback.resultId === item.result_id
              )}
              likeButtonClick={e => ariaResultFeedback(e, item, 'RELEVANT')}
              dislikeFilled={selected.some(
                feedback => feedback.type === 'NOT_RELEVANT' && feedback.resultId === item.result_id
              )}
              dislikeButtonClick={e => ariaResultFeedback(e, item, 'NOT_RELEVANT')}
              ariaResultData={item}
              source={source}
              ariaSearchID={searchId}
              ariaResultSharePDF={handleShareClick}
              floatingActionsLoading={floatingActionsLoading}
              setFloatingActionsLoading={setFloatingActionsLoading}
              suggestedAnswer={suggestedAnswer}
            />
          </div>
        )}
        <Stack>
          <Typography
            variant='subtitle1'
            maxHeight={110}
            sx={
              selectedDocumentId === item.result_id
                ? styles.descriptionSelected
                : styles.description
            }
            align='justify'
            overflow='hidden'
            textOverflow='ellipsis'
            onClick={() => ariaClickAnswerEvent(item, source)}
            dangerouslySetInnerHTML={{ __html: item.text }}
          />
          <Stack mt={2} direction='row' spacing={1} alignItems='center'>
            <Tooltip
              title={`${ariaCardResultsMapping[source]?.displayName}: ${customTitleCase(
                getAriaResultTitle(item[ariaCardResultsMapping[source]?.title], item, source)
              )}`}
              placement='bottom-start'>
              <Typography variant='h3' sx={styles.title} noWrap>
                {`${customTitleCase(
                  getAriaResultTitle(item[ariaCardResultsMapping[source]?.title], item, source)
                )}`}
              </Typography>
            </Tooltip>
            {SOURCE_WITH_SUBTITLE.includes(source) && (
              <Tooltip title={item.trade_name || item['trade-name']} placement='bottom-start'>
                <Typography component='span' variant='h3' noWrap sx={styles.subtitle}>
                  {(item.trade_name && `(${item.trade_name || ''})`) ||
                    (item['trade-name'] && `(${item['trade-name'] || ''})`)}
                </Typography>
              </Tooltip>
            )}
            {handleShowReg360Icon(item) && (
              <IconButton
                size='small'
                target='_blank'
                href={getReg360Url(
                  module,
                  item.application_type,
                  source,
                  item[UNIQUE_FIELD_MAPPING[source]]
                )}>
                <Reg360Icon />
              </IconButton>
            )}
          </Stack>
          <Stack mt={1} direction='row' display='flex' justifyContent='space-between'>
            <Stack direction='row' spacing={2} alignItems='center' useFlexGap flexWrap='wrap'>
              {Object.entries(ariaCardResultsMapping[source]).map(([key, value]) => {
                if (
                  (key === 'date' || key === 'date1') &&
                  item[value.type]?.includes('1900-01-01')
                ) {
                  return null;
                }
                return TAG_KEYS[source].includes(key) ? (
                  <Tooltip
                    key={key}
                    title={
                      key === 'date' || key === 'date1'
                        ? `${value.displayName}: ${item[value.type]?.split('T')[0] || 'UNKNOWN'}`
                        : `${value.displayName}: ${
                            source === 'hpra' && key === 'metadataOne'
                              ? getHPRAProductNumber(item[value.type])
                              : item[value.type] || 'UNKNOWN'
                          }`
                    }
                    placement='bottom-end'>
                    <Tags elevation={0}>
                      <Typography
                        noWrap
                        variant='subtitle2'
                        sx={
                          selectedDocumentId === item.result_id
                            ? styles.selectedTagText
                            : styles.tagText
                        }>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {key === 'date'
                          ? item[value.type]?.split('T')[0] || 'UNKNOWN'
                          : source === 'hpra' && key === 'metadataOne'
                          ? getHPRAProductNumber(item[value.type])
                          : item[value.type] || 'UNKNOWN'}
                      </Typography>
                    </Tags>
                  </Tooltip>
                ) : null;
              })}
            </Stack>
            <Stack direction='row' alignItems='center' justifyContent='center'>
              {item.type !== 'answer' && showFloatingButtons && (
                <Tooltip title={item?.score_confidence?.replace('_', ' ') || ''}>
                  <CircleIcon sx={styles[scoreConfidenceStyles[item.score_confidence]]} />
                </Tooltip>
              )}
              {item.type === 'answer' && (
                <Tooltip title='Best match'>
                  <AutoFixHighIcon fontSize='small' sx={{ color: 'primary.darkVariant' }} />
                </Tooltip>
              )}
              {!showFloatingButtons && (
                <CardActions
                  index={searchId}
                  isFavorite={item?.isFavorite ?? false}
                  inProject={item?.inProjects ?? []}
                  typeData={{
                    id: searchId,
                    type: 'aria_result',
                    source,
                    ariaResultData: item
                  }}
                  listView
                  type='ariaResult'
                  ariaResultsLoading={floatingActionsLoading}
                  setAriaResultsLoading={setFloatingActionsLoading}
                />
              )}
              {!showFloatingButtons && (
                <Tooltip title='Share file through Collaborate'>
                  <IconButton
                    size='medium'
                    aria-label='Share file through Collaborate'
                    onClick={handleShareClick}>
                    {floatingActionsLoading.collaborate ? (
                      <CircularProgress size={25} />
                    ) : (
                      <IosShare sx={styles.shareIcon} />
                    )}
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {state.shareLink?.open && state.shareLink?.ariaResultId === item?.result_id && <ShareLinks />}
    </Box>
  );
};

AriaResult.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      resultId: PropTypes.string.isRequired
    })
  ),
  ariaResultFeedback: PropTypes.func,
  ariaClickAnswerEvent: PropTypes.func.isRequired,
  source: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  selectedDocumentId: PropTypes.string,
  searchId: PropTypes.string.isRequired,
  showFloatingButtons: PropTypes.bool,
  suggestedAnswer: PropTypes.bool,
  fromUserProfile: PropTypes.bool
};

AriaResult.defaultProps = {
  selected: [],
  ariaResultFeedback: () => {},
  selectedDocumentId: '',
  showFloatingButtons: false,
  suggestedAnswer: false,
  fromUserProfile: false
};

export default AriaResult;

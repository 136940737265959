import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  InputAdornment,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { autoSearchOptionsStyles, styles } from './styles';
import { OptionsProps } from './types';
import OptionFooter from './OptionFooter';
import { isObject } from './utils';

const AutoSearchOptions = ({
  selectedCategory,
  selectedCategoryOptions,
  setSelectedCategoryOptions,
  handleClose,
  applyFilters,
  isLoading,
  setShowFilterSidebar,
  previousSelectedCategoryOptions,
  handleApplyClose
}: OptionsProps) => {
  const [searchText, setSearchText] = useState('');
  const [options, setOptions]: any = useState([]);

  const selectedCategoryValueKey = selectedCategory?.elasticSearchType || selectedCategory?.value;

  useEffect(() => {
    setOptions(selectedCategory.options);
  }, [selectedCategory]);

  const handleSearch = (value: any) => {
    setSearchText(value);
    const formattedOptions = selectedCategory.options.filter((option: any) => {
      let selectedOption = option;
      if (isObject(option)) {
        selectedOption = option.label;
      }
      return selectedOption.toLowerCase().includes(value.toLowerCase());
    });
    setOptions(formattedOptions);
  };

  const handleCheckbox = (value: any) => {
    if (
      Object.keys(selectedCategoryOptions).length > 0 &&
      (selectedCategoryOptions[selectedCategoryValueKey]?.includes(value) ||
        selectedCategoryOptions[selectedCategoryValueKey]
          ?.map((item: any) => item?.toLowerCase())
          ?.includes(value.toLowerCase()))
    ) {
      const formattedSelectedCategoryOptions = selectedCategoryOptions[
        selectedCategoryValueKey
      ].filter((option: any) => option.toLowerCase() !== value.toLowerCase());
      if (formattedSelectedCategoryOptions?.length) {
        setSelectedCategoryOptions({
          ...selectedCategoryOptions,
          [selectedCategoryValueKey]: formattedSelectedCategoryOptions
        });
      } else {
        const { [selectedCategoryValueKey]: _, ...others } = selectedCategoryOptions;
        setSelectedCategoryOptions(others);
      }
    } else {
      setSelectedCategoryOptions({
        ...selectedCategoryOptions,
        [selectedCategoryValueKey]:
          Object.keys(selectedCategoryOptions).length > 0 &&
          selectedCategoryOptions[selectedCategoryValueKey]
            ? [...selectedCategoryOptions[selectedCategoryValueKey], value]
            : [value]
      });
    }
  };

  const handleSelectAll = () => {
    const formattedOptions = options.map((each: any) => (isObject(each) ? each?.label : each));
    const isAllCategoryOptionsSelected =
      Object.keys(selectedCategoryOptions)?.length > 0 &&
      selectedCategoryOptions[selectedCategoryValueKey]?.length === options?.length;
    if (!isAllCategoryOptionsSelected) {
      setSelectedCategoryOptions({
        ...selectedCategoryOptions,
        [selectedCategoryValueKey]: formattedOptions
      });
    } else {
      const { [selectedCategoryValueKey]: _, ...others } = selectedCategoryOptions;
      setSelectedCategoryOptions(others);
    }
  };

  const isChecked = (each: any) => {
    return (
      Object.keys(selectedCategoryOptions).length > 0 &&
      selectedCategoryOptions[selectedCategoryValueKey]
        ?.map((item: any) => item?.toLowerCase())
        ?.includes(isObject(each) ? each.label?.toLowerCase() : each?.toLowerCase())
    );
  };

  return (
    <Box width={295} height={321}>
      <Box height={253}>
        <Box mb={1} sx={styles.header} onClick={handleClose}>
          <Button sx={styles.closeIconButton}>
            <CloseIcon sx={{ ...styles.icon, fontSize: 20 }} />
          </Button>
        </Box>
        <Box ml={3}>
          <TextField
            size='small'
            value={searchText}
            onChange={ev => handleSearch(ev.target.value)}
            placeholder={`Search ${selectedCategory.label}`}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon sx={styles.icon} />
                </InputAdornment>
              ),
              sx: autoSearchOptionsStyles.searchInput
            }}
          />
          <Box display='flex' justifyContent='flex-end'>
            {options?.length > 0 && (
              <Button
                sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  textTransform: 'none',
                  textDecorationLine: 'underline',
                  color: 'primary.600',
                  mr: 2.25
                }}
                onClick={handleSelectAll}>
                {Object.keys(selectedCategoryOptions)?.length > 0 &&
                selectedCategoryOptions[selectedCategoryValueKey]?.length === options?.length
                  ? 'Deselect all'
                  : 'Select all'}
              </Button>
            )}
          </Box>
          <Box sx={{ ...styles.scrollContainer, ...autoSearchOptionsStyles.content }}>
            {options?.map((each: any) => (
              <FormControlLabel
                key={isObject(each) ? each.label : each}
                control={
                  <Checkbox
                    checked={isChecked(each)}
                    size='small'
                    value={isChecked(each)}
                    onChange={() => handleCheckbox(isObject(each) ? each.label : each)}
                    style={{ color: isChecked(each) ? '#0D917D' : '#424242' }}
                  />
                }
                label={isObject(each) ? `${each.label} (${each.count})` : each}
                sx={{ wordBreak: 'break-word', color: 'gray.800' }}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box ml={2.3} mr={3} height={64} sx={{ ...styles.footer }}>
        <OptionFooter
          selectedCategory={selectedCategory}
          selectedCategoryOptions={selectedCategoryOptions}
          setSelectedCategoryOptions={setSelectedCategoryOptions}
          applyFilters={applyFilters}
          isLoading={isLoading}
          handleClose={handleClose}
          handleApplyClose={handleApplyClose}
          setShowFilterSidebar={setShowFilterSidebar}
          previousSelectedCategoryOptions={previousSelectedCategoryOptions}
        />
      </Box>
    </Box>
  );
};

export default AutoSearchOptions;

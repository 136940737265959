import { useContext } from 'react';

import { IosShare, ThumbDownOutlined, ThumbUpOutlined } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, Stack, Tooltip } from '@mui/material';
import CardActions from '../../CardsPage/components/CardActions';

import styles from '../styles/FloatingActions.styles';
import Store from '../../../store';
import ShareLinks from '../../../components/Dialogs/ShareLinks';

interface FloatingActionsProps {
  likeFilled: boolean;
  likeButtonClick: () => void;
  dislikeFilled: boolean;
  dislikeButtonClick: () => void;
  ariaSearchID: string;
  source: string;
  ariaResultData: any;
  suggestedAnswer: boolean;
  ariaResultSharePDF: () => Promise<void>;
  floatingActionsLoading: {
    favorite: boolean;
    collaborate: boolean;
  };
  setFloatingActionsLoading: () => Promise<void>;
}

const FloatingActions = ({
  likeFilled = false,
  likeButtonClick,
  dislikeFilled = false,
  dislikeButtonClick,
  ariaSearchID,
  source,
  ariaResultData,
  suggestedAnswer = false,
  ariaResultSharePDF,
  floatingActionsLoading,
  setFloatingActionsLoading
}: FloatingActionsProps) => {
  // get the query arguments from url
  const { state } = useContext(Store) as any;

  return (
    <Box sx={suggestedAnswer ? styles.suggestedAnswerFloatingContainer : styles.floatingContainer}>
      <Stack direction='row' spacing={0.5} justifyContent='center' alignItems='center'>
        <Tooltip title='Like'>
          <IconButton aria-label='Open in new tab' size='small' onClick={likeButtonClick}>
            <ThumbUpOutlined
              fontSize='inherit'
              sx={likeFilled ? styles.iconSelected : styles.icon}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title='Dislike'>
          <IconButton aria-label='Open in new tab' size='small' onClick={dislikeButtonClick}>
            <ThumbDownOutlined
              fontSize='inherit'
              sx={dislikeFilled ? styles.iconSelected : styles.icon}
            />
          </IconButton>
        </Tooltip>
        <CardActions
          index={ariaSearchID}
          isFavorite={ariaResultData.isFavorite ?? false}
          inProject={ariaResultData.inProjects ?? []}
          typeData={{
            id: ariaSearchID,
            type: 'aria_result',
            source,
            ariaResultData
          }}
          listView
          type='ariaResult'
          iconSize='small'
          ariaResultsLoading={floatingActionsLoading}
          setAriaResultsLoading={setFloatingActionsLoading}
        />
        <Tooltip title='Share file through Collaborate'>
          <IconButton
            aria-label='Share file through Collaborate'
            onClick={ariaResultSharePDF}
            size='small'>
            {floatingActionsLoading.collaborate ? (
              <CircularProgress size={20} />
            ) : (
              <IosShare fontSize='inherit' sx={styles.icon} />
            )}
          </IconButton>
        </Tooltip>
        {state.shareLink?.open && state.shareLink?.ariaResultId === ariaResultData?.result_id && (
          <ShareLinks />
        )}
      </Stack>
    </Box>
  );
};

export default FloatingActions;

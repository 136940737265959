import {
  Autocomplete,
  Chip,
  DialogActions,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField
} from '@mui/material';
import React, { useContext, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import DialogContent from '@mui/material/DialogContent';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import styles from './styles/ShareLinks.styles';
import { stringAvatar } from '../../helpers/utils';
import { createGroup, getUserListForGroup } from '../../api/services/Moxo';
import GlobalActions from '../../store/actions';
import GlobalStore from '../../store';
import AuthContext from '../../store/Auth/AuthProvider';

interface MemberList {
  id: string;
  name: string;
  email: string;
}

const CreateNewGroup = ({ closeCreateGroupDialog }: { closeCreateGroupDialog: any }) => {
  const { dispatch } = useContext(GlobalStore) as any;
  const { currentUser } = useContext(AuthContext);

  const [groupName, setGroupName] = React.useState<string>('');
  const [members, setMembers] = React.useState<Array<MemberList>>([]);
  const [memberList, setMemberList] = React.useState<Array<MemberList>>([]);
  const [processing, setProcessing] = React.useState<boolean>(false);
  const [fetchingUsers, setFetchingUsers] = React.useState<boolean>(false);
  const handleGroupNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupName(e.target.value);
  };
  const fetchUsers = async () => {
    try {
      setFetchingUsers(true);
      const { status, data: response } = await getUserListForGroup();
      if (status === 200) {
        const { body: listOfMembers }: { body: MemberList[] } = response;
        setMemberList(listOfMembers);
      }
      setFetchingUsers(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setFetchingUsers(false);
    }
  };
  const handleCreateGroup = async () => {
    try {
      setProcessing(true);
      const payload = {
        group_name: groupName,
        user_list: members
      };
      const response = await createGroup(payload);
      if (response.status === 200) {
        setGroupName('');
        setMembers([]);
        closeCreateGroupDialog();
        dispatch({
          type: GlobalActions.SET_ALERT,
          value: { status: true, message: `Group created`, color: 'success' }
        });
      } else {
        throw new Error('Failed to create group');
      }
      setProcessing(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setProcessing(false);
      dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: `Failed to create group` }
      });
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      <DialogContent sx={styles.paper}>
        <Stack spacing={2} mt='16px'>
          <TextField
            autoFocus
            margin='dense'
            id='name'
            label='Group Name'
            type='text'
            fullWidth
            variant='outlined'
            value={groupName}
            onChange={handleGroupNameChange}
          />
          <Autocomplete
            multiple
            id='tags-outlined'
            value={members}
            onChange={(event, newValue: any) => {
              if (!processing) {
                // @ts-ignore
                setMembers(newValue);
              }
            }}
            loading={fetchingUsers}
            options={memberList.filter((member: MemberList) => {
              if (currentUser?.sub) {
                return member.id !== currentUser?.sub;
              }
              return member.email !== currentUser?.email;
            })}
            getOptionLabel={option => option.name}
            renderInput={params => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <TextField {...params} variant='outlined' label='Members' placeholder='Add Members' />
            )}
            renderOption={(props, option) => {
              return (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <ListItem {...props} disablePadding>
                  <ListItemIcon>
                    <Avatar
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...stringAvatar(option.name)}
                      alt={option.name}
                      variant='circular'
                    />
                  </ListItemIcon>
                  <ListItemText primary={option.name} />
                </ListItem>
              );
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                // eslint-disable-next-line
                <Chip
                  variant='outlined'
                  label={option.name}
                  sx={{
                    '& .MuiChip-avatar': { color: 'white !important' }
                  }}
                  avatar={
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <Avatar {...stringAvatar(option.name)} alt={option.name} variant='circular' />
                  }
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...getTagProps({ index })}
                />
              ))
            }
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={handleCreateGroup}
          loading={processing}
          loadingPosition='end'
          variant='contained'
          endIcon={<SendOutlinedIcon fontSize='small' />}
          size='small'
          color='primary'
          disabled={!groupName || members.length === 0}
          sx={styles.sendBtn}>
          Create group
        </LoadingButton>
      </DialogActions>
    </>
  );
};
export default React.memo(CreateNewGroup);

import { memo, useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { getAdvanceSearchText, getLabelForDate } from '../../utils';
import landingPageStyles from '../../styles/LandingPage.styles';
import JourneyGroup from './JourneyGroup';

const Journeys = ({ activityData }: any) => {
  const groupByDataAndTerm = (data: any[]) => {
    const groupedByDate: any = {};
    data.forEach(item => {
      const date = item.date.split('T')[0]; // Extract date from ISO string
      const groupByField: string =
        item?.type === 'advancedSearch' ? getAdvanceSearchText(item, item?.source) : item?.term;
      if (!groupedByDate[date]) {
        groupedByDate[date] = {};
      }
      if (!groupedByDate[date][groupByField]) {
        groupedByDate[date][groupByField] = [];
      }

      groupedByDate[date][groupByField].push({
        ...item,
        date: new Date(item.date),
        dateWithoutTime: new Date(item.date).toISOString().split('T')[0],
        quantity: groupedByDate[date][groupByField].length
      });
    });

    return groupedByDate;
  };

  const journeyData = useMemo(() => {
    return groupByDataAndTerm(activityData);
  }, [activityData]);

  return (
    <Box>
      {Object.keys(journeyData).length !== 0 &&
        Object.entries(journeyData)?.map(([key, termValue]: any) => (
          <Box key={key} mb='12px'>
            <Stack justifyContent='center' width='100%'>
              <Box sx={landingPageStyles.dateChip}>
                <Typography>{getLabelForDate(key)}</Typography>
              </Box>
            </Stack>
            {termValue &&
              Object.entries(termValue)?.map(([term, applData]: any) => (
                <JourneyGroup key={term} term={term} data={applData} />
              ))}
          </Box>
        ))}
    </Box>
  );
};

export default memo(Journeys);

import { format, isToday, isYesterday } from 'date-fns';
import { uniq } from 'lodash';
import { ELASTIC_SEARCH_ATTRIBUTES_LABELS } from '../ResultsPage/utils/constants';
import RESULT_VIEW_TYPES from '../SearchResults/components/constants';
import { LinkIcon, Reg360Icon } from '../../assets/svgs/Icons';
import { advanceSearchQueryCreator } from '../UserProfile/ListButtons/AdvanceSearchButton';
import {
  cnfToAdvancedSearch,
  generateCNFQuery
} from '../../components/Header/utils/advanceSearchHelpers';
import { createSourceModuleDropdownMapping } from '../Home/utils';

const getLabelForDate = (dateString: string) => {
  const date = new Date(dateString);
  if (isToday(date)) {
    return 'Today';
  }
  if (isYesterday(date)) {
    return 'Yesterday';
  }
  return format(date, 'MMMM dd, yyyy'); // or any other desired format
};

const getSearchTypeText = (searchType: string, source: string | Record<string, string[]>) => {
  switch (searchType) {
    case 'ariaSearch':
      return 'documents';
    case 'quickSearch':
    case 'advancedSearch':
      if (source === 'ct') {
        return 'ct';
      }
      if (typeof source === 'object') {
        const sourceKeys = Object.keys(source);

        // If the first key is 'ct', return 'ct'
        if (sourceKeys[0] === 'ct') return 'ct';

        // Check if any source includes 'euctr' or has 'ct' as a key
        const isCtType = sourceKeys.some(key => key === 'ct' || source[key].includes('euctr'));

        // If all keys are of 'ct' type, return 'ct', otherwise return 'applications'
        const isAllCt = sourceKeys.every(key => key === 'ct' || source[key].includes('euctr'));

        if (isAllCt) {
          return 'ct';
        }
        if (isCtType) {
          return 'multiple';
        }
        return 'applications';
      }
      return 'applications';
    default:
      return searchType;
  }
};

const getViewType = (searchType: string) => {
  switch (searchType) {
    case 'applications':
      return RESULT_VIEW_TYPES.APPLICATION;
    case 'ct':
      return RESULT_VIEW_TYPES.CT;
    case 'documents':
      return RESULT_VIEW_TYPES.DOCUMENT;
    default:
      return RESULT_VIEW_TYPES.DOCUMENT;
  }
};

/**
 * Retrieves source information based on the provided source value.
 * @param source - The source value, either a string or a record of string arrays.
 * @returns An object containing the source information.
 */
const getSourceInfo = (source: string | Record<string, string[]>) => {
  try {
    const cleanLabel = (label: string) => label.replace(/ - |-/g, ' ');
    const getUniqueModules = (modules: string[]) => Array.from(new Set(modules.map(cleanLabel)));

    if (typeof source === 'string') {
      return {
        source,
        sourceTooltip: source,
        module: '',
        moduleTooltip: ''
      };
    }

    const sourceModuleMapping = createSourceModuleDropdownMapping(source);

    if (sourceModuleMapping.length > 1) {
      const allModules = sourceModuleMapping.flatMap(item => item.module.map(mod => mod.label));
      const uniqueModules = getUniqueModules(allModules);
      const moduleText = uniqueModules.join(', ');

      return {
        source: 'Multiple',
        sourceTooltip: sourceModuleMapping.map(item => cleanLabel(item.label)).join(', '),
        module: uniqueModules.length > 1 ? 'Multiple' : uniqueModules[0],
        moduleTooltip: moduleText
      };
    }

    const { label, module, value } = sourceModuleMapping[0];
    const sourceLabelTooltip = cleanLabel(label);
    // If the source is 'ct', show only the first letter of each word in the source label
    const sourceLabel =
      value === 'ct'
        ? sourceLabelTooltip
            .split(' ')
            .map(word => word[0])
            .join('')
        : sourceLabelTooltip;
    const uniqueModules = getUniqueModules(module.map(mod => mod.label));
    const moduleText = uniqueModules.join(', ');

    return {
      source: sourceLabel,
      sourceTooltip: sourceLabelTooltip,
      module: uniqueModules.length > 1 ? 'Multiple' : uniqueModules[0],
      moduleTooltip: moduleText
    };
  } catch {
    return {
      source: '',
      sourceTooltip: '',
      module: '',
      moduleTooltip: ''
    };
  }
};

const getAdvanceSearchText = (data: any, source: any) => {
  try {
    let localSearch: string | string[] = '';
    const currSource = Object.keys(source)[0];
    const { is505b2Data }: any = advanceSearchQueryCreator(data?.searchData);
    if (Object.keys(is505b2Data).length > 0) {
      localSearch = `${localSearch} ${is505b2Data.value}`;
    } else {
      const payload = cnfToAdvancedSearch(data?.cnf_query);
      localSearch = generateCNFQuery(
        payload,
        ELASTIC_SEARCH_ATTRIBUTES_LABELS[currSource.toLowerCase()]
      );
    }
    if (Object.keys(data?.dateFilter).length !== 0) {
      localSearch = `${localSearch} AND Approval Date: ${data?.dateFilter?.start_date} To ${data?.dateFilter?.end_date}`;
    }
    return localSearch;
  } catch (e) {
    return '';
  }
};

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const flattenSource = (data: any[]) =>
  Object.entries(data).flatMap(([key, values]) => [key, ...values]);

const getFilterText = (filters: any, source: any) => {
  if (source && filters) {
    let elasticSearchLabelMapping: any = {};
    const sourceList = flattenSource(source);

    sourceList.forEach((item: string) => {
      if (item in ELASTIC_SEARCH_ATTRIBUTES_LABELS) {
        elasticSearchLabelMapping = {
          ...elasticSearchLabelMapping,
          ...ELASTIC_SEARCH_ATTRIBUTES_LABELS[item]
        };
      }
    });

    return Object.entries(filters)
      .map(([key, values]: any) => {
        const filterKey =
          key in elasticSearchLabelMapping
            ? elasticSearchLabelMapping[key]
            : capitalizeFirstLetter(key.replace('_', ' '));

        const filterValues = uniq(
          values.map((value: any) => {
            if (typeof value === 'string') {
              return value;
            }
            if (typeof value === 'object' && value !== null) {
              return value.label || value.id;
            }
            return '';
          })
        ).join(', ');

        return `${filterKey}: ${filterValues}`;
      })
      .join('\n');
  }
  return '';
};

const normalizeFavoritesAndProjectsKey = (data: any[]) => {
  try {
    return data.map((item: any) => {
      return {
        ...item,
        inProjects: item?.inProjects ?? item?.in_projects ?? [],
        isFavorite: item?.isFavorite ?? item?.is_favorite ?? false
      };
    });
  } catch (e) {
    return data;
  }
};

const getOpenLinkTextAndIcon = (type: string) => {
  const mapping = {
    icon: LinkIcon,
    title: ''
  };
  switch (type) {
    case 'application':
      mapping.icon = Reg360Icon;
      mapping.title = 'Open Reg 360°';
      break;
    case 'quick_search':
    case 'advanced_search':
    case 'advanced_search_filters':
      mapping.icon = LinkIcon;
      mapping.title = 'Go to search results';
      break;
    default:
      mapping.icon = LinkIcon;
      mapping.title = `Go to search`;
      break;
  }
  return mapping;
};

const formatDate = (dateString: any) => {
  const date = new Date(dateString);
  const monthOptions: any = { month: 'short' };
  const dayOptions: any = { day: '2-digit' };
  const yearOptions: any = { year: 'numeric' };
  const month = date.toLocaleDateString(undefined, monthOptions);
  const day = date.toLocaleDateString(undefined, dayOptions);
  const year = date.toLocaleDateString(undefined, yearOptions);
  return `${month} ${day}, ${year}`;
};

export {
  getLabelForDate,
  getSearchTypeText,
  getSourceInfo,
  getAdvanceSearchText,
  getFilterText,
  getViewType,
  normalizeFavoritesAndProjectsKey,
  getOpenLinkTextAndIcon,
  formatDate
};
